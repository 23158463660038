import { Board } from "$/Backgammon"

const env = "process" in globalThis ? process.env : ({} as any)

const SERVER = env.SERVER || "wss://test-ws-room-chat.fly.dev/"
const WebSocket = globalThis.WebSocket || (await import("ws")).WebSocket
const LOG_MESSAGES = env.LOG_MESSAGES === "1"

const NAMES = [
  "Alice",
  "Bob",
  "Charlie",
  "David",
  "Eve",
  "Frank",
  "Grace",
  "Heidi",
  "Ivan",
  "Judy",
  "Karl",
  "Linda",
  "Mike",
  "Nancy",
  "Oscar",
  "Peggy",
  "Quentin",
  "Ruth",
  "Steve",
  "Trudy",
  "Ursula",
  "Victor",
  "Wendy",
  "Xavier",
  "Yvonne",
  "Zach",
]

const makeName = () =>
  NAMES.at(Math.random() * NAMES.length) +
  " " +
  NAMES.at(Math.random() * NAMES.length)?.slice(0, 1) +
  "."

const nameEl = document.getElementById("name") as HTMLInputElement
const roomEl = document.getElementById("room") as HTMLInputElement
const joinEl = document.getElementById("joinForm") as HTMLFormElement
const textEl = document.getElementById("text") as HTMLTextAreaElement
const dataEl = document.getElementById("data") as HTMLDivElement
const sendEl = document.getElementById("sendForm") as HTMLFormElement

nameEl.value = makeName()

let socket: WebSocket | undefined
const init = () => {
  if (socket) {
    // socket.close()
    // socket = undefined
  }

  const wsURL = `${SERVER}?name=${nameEl.value}&room=${roomEl.value}`
  const newSocket = new WebSocket(wsURL)
  newSocket.onopen = () => {
    socket = newSocket
    newSocket.onmessage = (e) => {
      console.log(e)
      const el = document.createElement("span")
      el.innerText = JSON.stringify(e.data)
      dataEl.appendChild(el)
    }

    newSocket.onclose = (e) => {
      console.log("closed", e)
    }

    newSocket.onerror = (e) => {
      console.log("error", e)
    }
  }
}

const send = () => {
  if (!socket) init()
  socket!.send(textEl.value)
}

joinEl.onsubmit = (e) => {
  e.preventDefault()
  init()
}

sendEl.onsubmit = (e) => {
  e.preventDefault()
  send()
}
